<template>
    <div class="contacts container-fluid px-0">
        <ContactsCover />
        <div class="container py-5">
            <div class="row">
                <div class="col-12 col-md-4 text-center text-md-left">
                    <h3>Данни за контакт:</h3>
                    <div class="contact-data mt-5">
                        <p class="address">4000 Пловдив, България</p>
                        <p class="address">ул. "Митрополит Панарет" 20</p>
                        <p class="address">office@cleaner.bg</p>
                        <p>087 630 5600</p>
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2091.701467632745!2d24.754055858585964!3d42.14787755837385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14acd1a22c4b78cf%3A0xb7be0dd0bd8810e5!2sKliynar%20Group%20Ltd.!5e0!3m2!1sen!2sbg!4v1622443660905!5m2!1sen!2sbg" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-auto mt-5">
                    <h4 class="contact-title pb-2 border-bottom border-top-0 border-left-0 border-right-0">СВЪРЖЕТЕ СЕ С НАС</h4>
                </div>
            </div>
            <div class="row">
                <EmailContact />
            </div>
        </div>
    </div>
</template>

<script>
    import ContactsCover from '../components/includes/contacts/ContactsCover'
    import EmailContact from '../components/includes/contacts/EmailContact'

    export default {
        name: 'Contacts',
        components: {
            ContactsCover,
            EmailContact
        }
    }
</script>