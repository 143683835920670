<template>
    <div class="contacts-cover cover container-fluid px-0 mt-5">
        <div class="container">
            <div class="row mx-2">
                <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                    <h4 class="text-white mx-5 mt-5 mb-4">КОНТАКТИ</h4>
                    <p class="text-white mx-5 my-4">Удовлетвлетвореността на нашите клиенти е наша мисия и ние се заемаме с нея с готовност и желание. Комфортът Ви е от особено значение, затова не се колебайте да се свържете с нас, за да обсъдим най-подходящите за Вас условия.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactsCover",
    }
</script>